const Colors = {
  principal: '#eeeff0',
  principalDark: '#bcbdbe ',
  principalLight: '#ffffff',
  principalText: '#000000',
  secondary: '#0087f2',
  secondaryDark: '#005bbf',
  secondaryLight: '#66b6ff',
  secondaryText: '#fafafa',
}
export default Colors
