import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'
import Colors from '../utils/Colors'
import Button from '@material-ui/core/Button'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Box from '@material-ui/core/Box'
import Logo from './images/logo'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Link from '@material-ui/core/Link'
import * as Scroll from 'react-scroll'
import Hidden from '@material-ui/core/Hidden'

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
    textAlign: 'center',
    color: '#141464',
  },
  logo: {
    width: '150px',
    justifyContent: 'center',
    textAlign: 'center',
  },
  navBar: {
    flex: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    // justifyContent: 'center',
    // textAlign: 'center',
  },
  appBar: {
    // transition: theme.transitions.create(['margin', 'width'], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
    // background: Colors.secondary,
    // color: Colors.principal,
    textAlign: 'center',
    justifyContent: 'center',
    maxHeight: '100px',
  },
}))

const Header = ({ siteTitle }) => {
  const classes = useStyles()

  //  const theme = useTheme()

  return (
    <div className={classes.root}>
      {/* <CssBaseline /> */}
      <AppBar position="fixed" elevation={0} className={clsx(classes.appBar)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <Box component="span" m={1} className={classes.logo}>
              <Logo />
            </Box>
          </IconButton>
          <Hidden only={['sm', 'xs']}>
            <nav className={classes.navBar} m={0}>
              <Link
                variant="button"
                className={classes.link}
                onClick={() => {
                  Scroll.scroller.scrollTo('persona', {
                    duration: 500,
                    delay: 100,
                    smooth: true,
                    offset: -100,
                  })
                }}
              >
                How do we help?
              </Link>
              <Link
                variant="button"
                className={classes.link}
                onClick={() => {
                  Scroll.scroller.scrollTo('services', {
                    duration: 500,
                    delay: 100,
                    smooth: true,
                    offset: -100,
                  })
                }}
              >
                Services
              </Link>
              <Link
                variant="button"
                className={classes.link}
                onClick={() => {
                  Scroll.scroller.scrollTo('products', {
                    duration: 500,
                    delay: 100,
                    smooth: true,
                    offset: -100,
                  })
                }}
              >
                Products
              </Link>
            </nav>
          </Hidden>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              Scroll.scroller.scrollTo('contact-us', {
                duration: 500,
                delay: 100,
                smooth: true,
                offset: -100,
              })
            }}
          >
            Contact Us
          </Button>
          {/*  */}
        </Toolbar>
      </AppBar>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
